<template>
  <router-view />
</template>
<script setup ts>
import { onMounted, onBeforeUnmount } from "vue";

function setScreenWidth() {
  const screenWidth = window.innerWidth;
  localStorage.setItem("ScreenWidth", screenWidth);
}

onMounted(() => {
  setScreenWidth();
  (function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?d5f8f360f4827ffb0af487a6ac4ccfbc";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
  })();
  window.addEventListener("resize", setScreenWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", setScreenWidth);
});

</script>